.elementor-widget-ova_team {
	text-align: center;
}
.ova-team {
	display: inline-block;
	position: relative;
	
	.content {
		display: grid;
		  
		&.four_column{
		  	grid-template-columns: 1fr 1fr 1fr 1fr;	
		  	grid-column-gap: 28px;	
		  	grid-row-gap: 33px;
		  	@media( max-width: 1200px ){
				grid-template-columns: 1fr 1fr;
			}
			@media( max-width: 767px ){
				grid-template-columns: auto;	
			}
		}

		&.three_column{
		  	grid-template-columns: 1fr 1fr 1fr;
		  	grid-column-gap: 28px;	
		  	grid-row-gap: 33px;
		  	@media( max-width: 1200px ){
				grid-template-columns: 1fr 1fr;
			}
			@media( max-width: 767px ){
				grid-template-columns: auto;	
			}
		}

		&.two_column{
		  	grid-template-columns: 1fr 1fr;
		  	grid-column-gap: 28px;	
		  	grid-row-gap: 33px;

		  	@media( max-width: 767px ){
				grid-template-columns: auto;	
			}
		}

		.item-team {
            transition: all .3s ease;
			box-shadow: 0px 8px 30px 0px #04064214;
			position: relative;
			overflow: hidden;

			&:hover {
				.img {
					
					img {
						transition: all .5s ease;
						transform: scale(1.05); 
					}
				}
				.info{
					
					.list-icon{
						margin-top: 15px;
						opacity: 1;
						height: 100%;
						margin-bottom: 0px;
					}
				}

			}

			.img {
				position: relative;    
				background-color: var(--heading); 
    			overflow: hidden;

				.img-wrapper {
					overflow: hidden;
				}

				img {
					transition: all .5s ease;
					display: block;
					height: 300px;
					width: 100%;
					object-fit: cover;
					transform: scale(1);
					
					@media( max-width: 1024px ){
						height: auto;
					}
				}
			}

			.info {
				background-color: transparent;
				padding: 20px;
				text-align: center; 
			    position: absolute;
    			width: 100%;
    			height: auto;
				bottom: 0;
				background: linear-gradient(180deg, rgba(77, 77, 77, 0) 0%, #060606 100%);

				.name-job {
                    .name {
                    	transition: all .3s ease;
						margin: 0; 
						font-size: 26px;
						font-weight: 400;
						color: #ffffff; 
						line-height: 1.4; 
						a {
							transition: all .3s ease;
							color: #ffffff;
							&:hover {
								transition: all .3s ease;
								color: var(--primary);
							}
						}
					}
					.job {
						color: #ffffff;
						transition: all .3s ease;
						margin: 0;
						font-size: 16px ;
						line-height: 1.4;
						text-transform: uppercase;
						font-family: var(--primary-font);
					}
				}

				.list-icon{
				    opacity: 0;
    				// height: 0;
    				margin-top: 0px;
    				transition: all .15s linear;
    				margin-bottom: -28px;
    				@media( max-width: 1024px ){
						height: 100%;
						opacity: 1;
						margin-top: 15px;
						margin-bottom: 0px;
					}
					
    				.box-list-icon{
    					display: flex;
					    justify-content: center;
					    align-items: center;
					    gap: 13px;

        				.item{
	    					font-size:24px;
	    					color: #ffffff;

	    					&:hover{
	    						color: var(--primary);
	    					}
    					}
    				}

				}

			}
		}
	}
}